import React from 'react';
import { ProductTile, ProductTileSkeleton } from '@hpstellar/core';
import loadable from '@loadable/component';

import ProductJsonld from '../product-json-ld';
import AccessibleElement from '../../../ui/components/accessible-element';
import AboveTheFoldOnlyServerRender from '../../../shared/components/above-the-fold-only-server-render';
import ConditionalWrapper from '../../../shared/components/conditional-wrapper';
import { Helpers } from '../../../core/src/helpers';

const VisIDProductTile = loadable(() => Helpers.retryFunc(() => import('../visId/product-tile')));
const VisIDProductTileHorizontal = loadable(() => Helpers.retryFunc(() => import('../visId/product-tile-horizontal')));

const parseTranslations = translations => {
    return translations
        ? {
              ...(translations || {}),
              ...(translations.buttons
                  ? {
                        buttons: {
                            ...translations.buttons,
                            ...(translations.buttons.compareBtnText
                                ? {
                                      compareBtnText: (
                                          <h6 className="compareLabel">{translations.buttons.compareBtnText}</h6>
                                      ),
                                  }
                                : {}),
                        },
                    }
                  : {}),
          }
        : {};
};

/**
 * Displays ProductTile stellar component and associated jsonld.
 * Use this component in cases where the ProductTile needs to be rendered along with the jsonlds.
 *
 */
export default ({
    price,
    product,
    translations,
    lazyLoadSettings,
    visId,
    containerProps,
    horizontal,
    forceShowPlaceholder,
    ProductTileComponentProp,
    ...rest
}) => {
    const ProductTileComponent = ProductTileComponentProp
        ? ProductTileComponentProp
        : visId
          ? horizontal
              ? VisIDProductTileHorizontal
              : VisIDProductTile
          : ProductTile;
    const { sku } = product || {};
    const accessibilityProps = product && { 'data-sku': sku };
    return (
        <AccessibleElement element="div" key={sku} clickTarget {...accessibilityProps} {...(containerProps || {})}>
            <ConditionalWrapper
                condition={!!lazyLoadSettings || forceShowPlaceholder}
                wrapper={children => (
                    <AboveTheFoldOnlyServerRender
                        placeholder={<ProductTileSkeleton />}
                        forceShowPlaceholder={forceShowPlaceholder}
                        {...lazyLoadSettings}
                    >
                        {children}
                    </AboveTheFoldOnlyServerRender>
                )}
            >
                {/* TODO: probably need to ask gfe for a ref prop on product tile directly */}
                <div className="tileRef" ref={rest.productRef}>
                    <ProductTileComponent
                        {...rest}
                        translations={parseTranslations(translations)}
                        price={price}
                        product={product}
                        ratingSpaceReserved={true}
                    />
                </div>
            </ConditionalWrapper>
            {!forceShowPlaceholder && product && <ProductJsonld product={{ ...product }} price={price} />}
        </AccessibleElement>
    );
};
