import React from 'react';

import { Helpers } from '../../core';
import JsonLd from '../../shared/components/json-ld';
import { useGetProductPrice } from '../../hooks/useProductPrice';

const getProductJsonLdImageData = image =>
    image ? (typeof image === 'string' ? image : image.cdn || image.lrg || image.sm || image.src || image.url) : null;
export const getProductJsonLdImageArrayData = images =>
    images instanceof Array
        ? {
              image: images.reduce((allImages, img) => {
                  let nextImage = getProductJsonLdImageData(img);
                  if (nextImage) allImages.push(nextImage);
                  return allImages;
              }, []),
          }
        : null;

const getProductJsonLdBrand = ({ brand, facet_subbrand }) =>
    (brand || facet_subbrand) && {
        brand: {
            '@type': 'Brand',
            name: brand || facet_subbrand,
        },
    };

const getProductJsonLdRatings = (name, rating, numReviews) => {
    let ratingValue, reviewCount;

    // if rating is an object, get the score and number of reviews from it
    if (rating && typeof rating === 'object') {
        let { score, reviewsCount } = rating;
        ratingValue = score;
        reviewCount = typeof reviewsCount === 'number' ? reviewsCount : numReviews;
    } else {
        ratingValue = rating;
        reviewCount = numReviews;
    }
    //TODO: can be options once we have offers so passing in even if 0 reviews
    return (
        !isNaN(ratingValue) &&
        !isNaN(reviewCount) && {
            aggregateRating: {
                '@type': 'AggregateRating',
                ratingValue,
                reviewCount,
                itemReviewed: {
                    '@type': 'IndividualProduct',
                    name,
                },
            },
        }
    );
};

const getProductJsonLdOffers = ({
    sku,
    linkUrl,
    productUrl,
    instock,
    salePrice,
    listPrice,
    isOOS,
    price,
    shipCutOffTime: cutoffTime,
}) => {
    let url = linkUrl || productUrl; // linkUrl is the old field in mongo collection, productUrl is from ETR API
    if (typeof url == 'string' && /^\//.test(url)) {
        url = Helpers.getAbsoluteRedirectPath(url);
    }

    let jsonLdPrice = price?.salePrice || price?.regularPrice || salePrice || listPrice;
    const oos = typeof price?.isOOS === 'boolean' ? price.isOOS : isOOS;
    return (
        jsonLdPrice && {
            offers: {
                sku,
                '@type': 'Offer',
                priceCurrency: 'USD',
                price: jsonLdPrice,
                ...(url && { url }),
                ...([typeof instock, typeof oos].includes('boolean') && {
                    availability:
                        instock || oos === false ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock',
                }),
                ...(cutoffTime && {
                    shippingDetails: {
                        '@type': 'OfferShippingDetails',
                        deliveryTime: {
                            cutoffTime,
                        },
                    },
                }),
            },
        }
    );
};

// note: products from swiftype have a slightly different syntax.
// For example, prdOverview would be in the root of the product rather than in the attributes field
export const getProductJsonLdData = (
    {
        name,
        image,
        sku,
        rating,
        numReviews,
        gtin,
        brand,
        facet_subbrand,
        listPrice,
        salePrice,
        attributes,
        prdOverview,
        linkUrl,
        productUrl,
        instock,
        isOOS,
        price,
        mfpartnumber,
        itemcondition,
        shipCutOffTime,
    },
    seo
) => {
    const { prdOverview: attributeProductOverview } = attributes || {};
    const { description } = seo || {};
    const productDescription = description || attributeProductOverview || prdOverview;
    const jsonLdData = {
        '@context': 'https://schema.org/',
        '@type': 'Product',
        name: name,
        sku: sku,
        image,
        ...((gtin && { gtin: gtin }) || {}),
        ...((mfpartnumber && { mpn: mfpartnumber }) || {}),
        ...(productDescription && {
            description: productDescription,
        }),
        ...(itemcondition && { itemCondition: `https://schema.org/${itemcondition}Condition` }),
        ...getProductJsonLdBrand({ brand, facet_subbrand }),
        ...getProductJsonLdRatings(name, rating, numReviews),
        ...getProductJsonLdOffers({
            sku,
            instock,
            isOOS,
            linkUrl,
            productUrl,
            salePrice,
            listPrice,
            price,
            shipCutOffTime,
        }),
    };
    return jsonLdData;
};

const getDefaultImagesFromPrice = priceObj => {
    let imgList = [];
    if(priceObj!=null && priceObj.fullImages!=null) {
        imgList = Object.entries(priceObj.fullImages).map(([key,value]) => value); 
    }
    return imgList;
}

export default ({ product, price }) => {
    if (!product) return null;

    const priceObj = useGetProductPrice(!price ? product : undefined) || price;

    let parsedImages = getProductJsonLdImageArrayData((product.images && product.images?.length >= 1) ? product.images : getDefaultImagesFromPrice(priceObj));
    let { image } = parsedImages || {};
    
    return product.name ? (
        <JsonLd
            type="product"
            data={getProductJsonLdData({ price: priceObj, ...product, image })}
            shouldNotUseDefaults={true}
        />
    ) : null;
};
